export const gradations = [
    {
        id: "service-gradiation-1",
        title: "Discusión",
        desc: "Nos ponemos en contacto con el cliente para entender su situación y necesidades antes de hacer cualquier propuesta.",        
    },
    {
        id: "service-gradiation-2",
        title: "Revisión de Propuesta",
        desc: "Desarollamos y revisamos con el cliente la propuesta hasta aclarar todas las dudas. El cliente tiene la oportunidad de revisar y tomar la decisión sin presiones.",        
    },
    {
        id: "service-gradiation-3",
        title: "Implementación",
        desc: "Una vez aceptada la propuesta, continuamos con la implementación necesaria. Terminamos de aclarar cualquier duda y nos aseguramos que el cliente este listo para usar el servicio.",        
    },
    {
        id: "service-gradiation-4",
        title: "Soporte Continuo",
        desc: "Seguimos estando disponible para cualquier soporte, duda o necesidad del cliente.",        
    }
]