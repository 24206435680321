import React from 'react'
import Section, { Container, Row, Col } from '../../../components/ui/wrapper'
import ProgressCirle from '../../../components/ui/progress-circle/layout-one'
import { SectionWrap, FeatureListProgressWrap, ProgressCircleWrap, FeatureList } from './style'
import Heading from '../../../components/ui/heading'
import PropTypes from 'prop-types'

const ReasonFacts = ({headingStyle, sectionStyle}) => {
    return (
        <Section {...sectionStyle}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Heading {...headingStyle}>¿Sabías que los compradores <b>confían menos</b> en empresas que no tienen un correo electrónico personalizado?</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <FeatureListProgressWrap position="left">
                            <ProgressCircleWrap>
                                <ProgressCirle value={33} />
                            </ProgressCircleWrap>
                            <FeatureList>
                                <h6>Duda de la fiabilidad del negocio</h6>                                
                            </FeatureList>
                        </FeatureListProgressWrap>
                    </Col>
                    <Col lg={4}>
                        <FeatureListProgressWrap>
                            <ProgressCircleWrap>
                                <ProgressCirle value={24} />
                            </ProgressCircleWrap>
                            <FeatureList>
                                <h6>Les preocupa compartir información personal</h6>                               
                            </FeatureList>
                        </FeatureListProgressWrap>
                    </Col>
                    <Col lg={4}>
                        <FeatureListProgressWrap>
                            <ProgressCircleWrap>
                                <ProgressCirle value={23} />
                            </ProgressCircleWrap>
                            <FeatureList>
                                <h6>Evitan brindar datos de sus tarjetas de crédito</h6>                               
                            </FeatureList>
                        </FeatureListProgressWrap>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

ReasonFacts.propTypes = {
    sectionStyle: PropTypes.object,
    headingStyle: PropTypes.object
}

ReasonFacts.defaultProps = {
    sectionStyle: {
        bgColor: '#f8f8f8',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    headingStyle: {
        as: "h4",
        textalign: "center",
        mb: "50px",
        responsive: {
            medium: {
                mb: "10px"
            }
        }
    }
}

export default ReasonFacts
