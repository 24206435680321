import React from 'react'
import Img from 'gatsby-image'
import Image from '../../../image'
import Button from '../../button'
import {
    PricingTableWrap,
    PricingTableInner,
    PricingTableHead,
    PricingTableTitle,
    PricingTableImage,
    PricingTablePrice,
    PricingTableBody,
    PricingTableBtn,
    PricingTableList,
    PricingTableFeatureMark,
    PricingTableIntro
} from './style'
//import * as typeformEmbed from '@typeform/embed'

const PricingTable = ({ title, period, currencyText, periodText, image, price, isFeatured, path, features, introText }) => {
    const btnStyle = {
        varient: !isFeatured ? 'outlined' : 'contained',
        hover: !isFeatured ? "true" : 2
    }
    const gatsbyImg = image && image.childImageSharp;
    const imageSrc = gatsbyImg || image;
    let boxImage;
    if (imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
        boxImage = <Img fixed={imageSrc.fixed} alt="popup video" />;
    } else if (imageSrc.fluid) {
        boxImage = <Image fluid={imageSrc.fluid} alt="popup video" />
    } else {
        boxImage = <img src={imageSrc} alt="popup video" />
    }

    const requestInfoHandler = (e) => {
        // https://github.com/Typeform/embed/blob/HEAD/demo/popup-api.html
        // https://www.npmjs.com/package/@typeform/embed
        e.preventDefault();
        
        var options = 
          {
            mode: 'popup', 
            transferableUrlParameters: ['utm_source'], 
            size: 75
          };
    
        window.location = "https://form.typeform.com/to/cbDi5JbJ";
        //typeformEmbed.makePopup('https://form.typeform.com/to/cbDi5JbJ', options).open();
      }

    return (
        <PricingTableWrap>
            <PricingTableInner>
                {isFeatured && (
                    <PricingTableFeatureMark>
                        <span>Más Vendido</span>
                    </PricingTableFeatureMark>
                )}
                <PricingTableHead>
                    {title && <PricingTableTitle>{title}</PricingTableTitle>}
                    {image && (
                        <PricingTableImage>
                            {boxImage}
                        </PricingTableImage>
                    )}
                    {price && (
                        <PricingTablePrice>
                            <sup>$</sup>
                            <h6>{price}</h6>
                            {periodText && 
                                <sub>{currencyText} /{periodText}</sub>}
                            {!periodText &&
                                <sub>{currencyText} /{period === 'monthly' ? 'mo' : 'y'}</sub>}
                        </PricingTablePrice>
                    )}
                </PricingTableHead>
                <PricingTableBody>
                    <PricingTableBtn>                            
                            <Button {...btnStyle} onClick={requestInfoHandler}>Solicitar Info</Button>
                     </PricingTableBtn>
                    {introText && <PricingTableIntro>{introText}</PricingTableIntro>}
                    {features && (
                        <PricingTableList>
                            {features.map(feature => <li key={feature.id}><i className="fa fa-check"></i><span>{feature.text}</span></li>)}
                        </PricingTableList>
                    )}
                </PricingTableBody>
            </PricingTableInner>
        </PricingTableWrap>
    )
}

export default PricingTable
