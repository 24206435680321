import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-tdw'
import Footer from '../containers/layout/footer/footer-one'
import CTAArea from '../containers/global/cta-area/section-two'
import PricingBoxOne from '../containers/elements/pricing-table/section-one'
import IntroText from '../components/tdw/intro-text'
import OurValue from '../components/tdw/our-value'
import HowToEngage from '../components/tdw/how-to-engage'
import BlogArea from '../containers/tdw/blog-area'
import ReasonFacts from '../containers/tdw/reason-facts'

const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Correo Electrónico Personalizado en México"/>
    <Header/>
    <main className="site-wrapper-reveal"> 
      <IntroText />  
      <ReasonFacts />   
      <PricingBoxOne />
       {/*<ServicesArea/> */}       
      <OurValue />
      <CTAArea/>   
      <HowToEngage />     
       {/*<TestimonialArea/>
     <ContactArea/>*/}
      <BlogArea />
    </main>
    <Footer/>
  </Layout>
)

export default IndexPage
 