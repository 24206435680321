import React from 'react'
// import Typical from 'react-typical'
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap, TypedTextWrap } from './style'

const IntroText = () => {
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <TypedTextWrap>
                            <span className="not-typical">E-mail personalizado sin complicaciones <br /> para  </span>
                            <Typed
                                strings={['abogados', 'arquitectos', 'contadores', 'recursos humanos', 'comerciantes', 'asesores de seguros', 'asesores inmobiliarios', 'negocios profesionales']}
                                typeSpeed={60}
                                backSpeed={80}
                                loop
                            />
                            <span className="not-typical">.</span>
                        </TypedTextWrap>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default IntroText
