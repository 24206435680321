import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import imageOne from '../../../../data/images/icons/icon-29-90x90.png'
import imageTwo from '../../../../data/images/icons/icon-30-90x90.png'
import ImgGSuiteLogo from '../../../../assets/tdw/gsuite-logo.png'
import ImgOfficeLogo from '../../../../assets/tdw/Office_365_logo.png'
import ImgRackspaceLogo from '../../../../assets/tdw/rackspaceemail-logo.png'
import PricingTable from '../../../../components/ui/pricing-table/layout-one'
import SectionTitle from '../../../../components/ui/section-title'
import { SectionWrap } from './style'

const PricingTableSection = () => {
    const pricingItems = [
        {
            id: "pricing-basic",
            title: "Rackspace Email",
            image: ImgRackspaceLogo,
            is_featured: true,
            price: 3,        
            periodText:"usuario / mes",
            currencyText:"USD" , 
            introText: "Para empresas con necesidades sencillas de comunicación sin sacrificar seguridad. Es un buento de partida que permite brincar a futuro a opciones más avanzadas.",
            features: [                
                {
                    id: "basic-feature-1",
                    text: "@ Dominio Propio"
                },
                {
                    id: "basic-feature-2",
                    text: "Capacidad 25 GB"
                },
                {
                    id: "basic-feature-3",
                    text: "Accesible en Móviles y Outlook"
                }
                ,
                {
                    id: "basic-feature-4",
                    text: "Protección de SPAM y Virus"
                },
                {
                    id: "basic-feature-5",
                    text: "Alias de correo"
                },
                {
                    id: "basic-feature-6",
                    text: "Archivos adjuntos 25 MB "
                },
                {
                    id: "basic-feature-7",
                    text: "Disponible 100% garantizado"
                }
            ]
        },
        {
            id: "pricing-proffessional",
            title: "Office 365",
            image: ImgOfficeLogo,            
            price: 6,           
            periodText:"usuario / mes",
            currencyText:"USD" ,
            introText: "Para empresas con necesidades de colaboración y seguridad más avanzadas. Disponible herramientas para Videollamadas y trabajo remoto. Ideal para empresas con experiencia usando productos de la suite de Microsoft como Word, Excel, Powerpoint.",
            features: [                
                {
                    id: "office365-feature-1",
                    text: "@ Dominio Propio"
                },
                {
                    id: "office365-feature-2",
                    text: "Capacidad 50 GB"
                },
                {
                    id: "office365-feature-3",
                    text: "Accesible en Móviles y Outlook"
                }
                ,
                {
                    id: "office365-feature-4",
                    text: "Protección Premium de SPAM y Virus"
                },
                {
                    id: "office365-feature-5",
                    text: "Alias de correo"
                },
                {
                    id: "office365-feature-6",
                    text: "Archivos adjuntos 50 MB "
                },
                {
                    id: "office365-feature-7",
                    text: "Disponible 100% garantizado"
                },
                {
                    id: "office365-feature-8",
                    text: "Opción a herramientas de colaboración y productividad."
                }
            ]
        },
        {
            id: "pricing-business",
            title: "Google GSuite",
            image: ImgGSuiteLogo,
            price: 6,   
            periodText:"usuario / mes",
            currencyText:"USD" ,
            introText: "Para empresas con necesidades de colaboración y seguridad más avanzadas. Disponible herramientas para Videollamadas y trabajo remoto. Ideal para empresas con experiencia usando productos de la suite de Google como GMail, GDrive, entre otras.",
            features: [                
                {
                    id: "gsuite-feature-1",
                    text: "@ Dominio Propio"
                },
                {
                    id: "gsuite-feature-2",
                    text: "Capacidad 50 GB"
                },
                {
                    id: "gsuite-feature-3",
                    text: "Accesible en Móviles y Outlook"
                },
                {
                    id: "gsuite-feature-4",
                    text: "Protección Premium de SPAM y Virus"
                },
                {
                    id: "gsuite-feature-5",
                    text: "Alias de correo"
                },
                {
                    id: "gsuite-feature-6",
                    text: "Archivos adjuntos 50 MB "
                },                                
                {
                    id: "gsuite-feature-7",
                    text: "Disponible 100% garantizado"
                },
                {
                    id: "gsuite-feature-8",
                    text: "Opción a herramientas de colaboración y productividad."
                }
            ]
        }
    ];

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="45px"
                            title="<span>1 més gratis</span> en planes de Rackspace."
                            subtitle="Planes de Correo Personalizado"
                        />
                    </Col>
                </Row>
                <Row alignitems="center">
                    {pricingItems.map(pricing => (
                        <Col lg={4} md={6} key={pricing.id}>
                            <PricingTable
                                title={pricing.title}
                                image={pricing.image}
                                price={pricing.price}
                                path={pricing.path}
                                features={pricing.features}
                                isFeatured={pricing.is_featured}
                                periodText={pricing.periodText}
                                currencyText={pricing.currencyText}
                                introText={pricing.introText}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </SectionWrap>
    )
}

export default PricingTableSection
