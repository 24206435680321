import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Blog from '../../../components/blog/layout-one'
import RecentPostList from '../../blog/recent-post-list'
import { BlogWrapper, LeftBox, RecentBlogWrap, FeaturedBlogWrap } from './blog-area.style'

const BlogArea = props => {
    const { allContentfulBlog } = useStaticQuery(graphql`
            query BlogAreaQuery {
                allContentfulBlog (sort: { fields: [publishDate], order: DESC }, limit: 2) {
                    edges {
                        node {
                            id
                            title
                            urlSlug  
                            introduction  
                            publishDate  
                            featuredImage {
                                fluid(maxWidth: 300, quality: 100) {
                                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                                    ...GatsbyContentfulFluid
                                }
                              }       
                        }
                    }
                }    
            }
    `)

    return (
        <BlogWrapper>
            <Container>
                <Row>
                    <Col lg={4}>
                        <LeftBox>
                            <SectionTitle
                                align="left"
                                title="Noticias"
                                subtitle="Blog y Noticias"
                            />
                            <RecentBlogWrap>
                                {/*<RecentPostList />*/}
                            </RecentBlogWrap>
                        </LeftBox>
                    </Col>
                    <Col lg={8}>
                        <Row>                            
                            {allContentfulBlog && allContentfulBlog.edges.map(featuredBlog => (
                                <Col md={6} key={featuredBlog.node.id}>
                                    <FeaturedBlogWrap>                                        
                                       <Blog content={{ 
                                            fields : {
                                                slug : 'blog/' + featuredBlog.node.urlSlug  
                                            },
                                            frontmatter : 
                                            {
                                                title : featuredBlog.node.title,  
                                                'featured_image' : featuredBlog.node.featuredImage,
                                                format : 'contentfulImage'
                                            },
                                            excerpt : featuredBlog.node.introduction
                                           }} />
                                    </FeaturedBlogWrap>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BlogWrapper>
    )
}

export default BlogArea;